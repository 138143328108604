import React from "react"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Main from "../components/Main"
import Links from "../components/Links"
import Title from "../components/Title"
import Header from "../components/Header"
import BodyWrapper from "../components/BodyWrapper"

export default () => (
  <Layout>
    <SEO title="Home" />
    <Main>
      <Header>
        <Title text="timothy brunette" to="/" />
        <Links />
      </Header>
      <BodyWrapper>
        <p>
          Hi there and welcome to my internet homepage. You're either visiting
          this because you somehow know me, or my website has blown up due to an
          serious error in google's{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://en.wikipedia.org/wiki/PageRank"
          >
            pagerank
          </a>{" "}
          algorithm.
        </p>
        <p>
          I'm currently the co-founder and CTO of {" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://cryptotaxcalculator.io/"
          >
           CryptoTaxCalculator 
          </a>
          , helping crypto users sort our their tax nightmare. I started web-programming at
          fifteen, when I created an online store to expand my winter clothing
          business. At sixteen I participated in and 'won' the 3301 puzzle (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://lmgtfy.com/?q=cicada+3301"
          >
            lmgtfy
          </a>{" "}
          or{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.rollingstone.com/culture/culture-news/cicada-solving-the-webs-deepest-mystery-84394/"
          >
            read the rolling stones
          </a>
          ). Since then, I've done a undergrad in Space Engineering, Masters in IT, and worked in multiple engineering roles.
        </p>
        <p>
          Between this and work, I find time to
          go surfing, bouldering, deadlifting, and travelling on a budget.
        </p>
      </BodyWrapper>
    </Main>
  </Layout>
)
